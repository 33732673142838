import React from 'react';
import { Section } from './section';
import facebookImage from '../images/icon-facebook.png';
import * as styles from './contactSection.module.css';
import Iframe from 'react-iframe';

export const ContactSection = (props) => {
  return (
    <Section id="contact" title="Contact">
      <p className={styles.contactSection}>
        &nbsp;Je vous accueille sur rendez-vous au :<br />
        <span>
          <strong>2 rue de Port Maria</strong>
          <br />
          <strong>44300 NANTES</strong>
          <br />
        </span>
        entre Saint Joseph de porterie et Carquefou
        <br /> proche des écoles de la Chantrerie et de l’ICAM.
        <br />
        <br /> Pour tous renseignements, prise de rendez-vous ou achat de bon cadeau,
        <br /> N’hésitez pas à me contacter au
        <br />
        <span className={styles.phoneNumber}>07 68 50 29 60</span>
        <br />
        <a href="mailto:contact@aude-bien-etre.fr">contact@aude-bien-etre.fr</a>
        <br />
        <br />
        <a href="https://www.facebook.com/aude.bien.etre44/" target="_blank" rel="noreferrer" className={styles.img}>
          <img src={facebookImage} alt="icône Facebook" className={styles.img} />
          <br />
          <span>Facebook</span>
        </a>
        <br />
      </p>
      <Iframe
        width="100%"
        height="350"
        url={`https://www.google.com/maps/embed/v1/place?key=AIzaSyACEjoH0CfpXsxMqw0_GdSzJ81inLTnBv0&q=aude+bien+etre,Nantes+France`}
        allowfullscreen></Iframe>
    </Section>
  );
};
