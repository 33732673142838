import React from 'react';
import { Section } from './section';
import massageImage from '../images/relaxation_pano_1.jpg';

import * as styles from './section.module.css';

export const RelaxationSection = (props) => {
  return (
    <Section id="relaxation" title="De la Relaxation en groupe ou en individuel.">
      <img src={massageImage} alt="" className={styles.img} />
      <p>
        La{' '}
        <a className={styles.link} href="/relaxation">
          relaxation
        </a>{' '}
        se pratique avec des techniques dynamiques et d’autres statiques.
        <br />
        Elle va vous amener à vous recentrer, vous détendre et vous évader.
      </p>
    </Section>
  );
};
