import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import '../css/global.css';
import '../css/variables.css';
import * as styles from './index.module.css';

import { Header } from '../components/header';
import { AccueilSection } from '../components/accueilSection';
import { MassagesSection } from '../components/massagesSection';
// import { EvenementRelaxationSection } from '../components/evenementRelaxationSection';
// import { BonCadeauSection } from '../components/bonCadeauSection';
// import { SaintValentinSection } from '../components/saintValentinSection';
// import { NoelSection } from '../components/noelSection';
import { RelaxationSection } from '../components/relaxationSection';
import { ExpressionCorporelleSection } from '../components/expressionCorporelleSection';
import { ContactSection } from '../components/contactSection';
import { AboutSection } from '../components/aboutSection';
import { Footer } from '../components/footer';

const Home = ({ data }) => {
  return (
    <div className={styles.main}>
      <div>
        <Helmet>
          <html lang="fr" amp />
          <meta charSet="utf-8" lang="fr" />
          <meta
            name="description"
            content="Aude Bien-Être est un espace dédié aux massages, à la relaxation et à l'expression corporelle, situé à Saint Joseph de Porterie, au nord-est de Nantes, proche de Carquefou. Sont proposés des massages à l’huile(Abhyanga(énergétique), Shirotchampie (cuir chevelu), Californien, Taimabhyanga (femme enceinte)) ou massages habillés(Thaï traditionnel (Nuad Boran), Thaï assis, Thaï des pieds)"
          />
          <title>{data.site.siteMetadata.title}</title>
          <link rel="canonical" href="http://www.aude-bien-etre.fr" />
          {/* <link rel="stylesheet" type="text/css" href="../css/variables.css" /> */}
        </Helmet>
        <Header></Header>
      </div>
      <div className={styles.mainContent}>
        {/* <EvenementRelaxationSection /> */}
        {/* <SaintValentinSection /> */}
        {/* <NoelSection /> */}
        <AboutSection />
        <AccueilSection></AccueilSection>
        <MassagesSection></MassagesSection>
        <RelaxationSection></RelaxationSection>
        <ExpressionCorporelleSection></ExpressionCorporelleSection>
        <ContactSection />
        <Footer />
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Home;
