import React from 'react';
import { section } from './section.module.css';
export const Section = ({ id, title, children }) => {
  return (
    <div id={id} className={section}>
      <h1>{title}</h1>
      {children}
    </div>
  );
};
