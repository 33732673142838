import React from 'react';
import { Section } from './section';
import { Link } from 'gatsby';

import massageImage from '../images/massage_pano_1.jpg';
import * as styles from './section.module.css';

export const MassagesSection = () => {
  return (
    <Section id="massage" title="Des Massages adaptés à vos envies : à l’huile ou habillés, énergétiques ou cocooning">
      <div>
        <img src={massageImage} alt="" className={styles.img} />
        <div>
          <h2>Les massages à l’huile</h2>
          <p>
            <Link className={styles.link} to="/massages/#abhyanga">
              Abhyanga
            </Link>
            ,{' '}
            <Link className={styles.link} to="/massages/#shirotchampi">
              Shirotchampi
            </Link>{' '}
            (cuir chevelu),{' '}
            <Link className={styles.link} to="/massages/#californien">
              Californien
            </Link>{' '}
            (cocooning),{' '}
            <Link className={styles.link} to="/massages/#massageJaponais">
              Japonais des mains
            </Link>
            ,{' '}
            <Link className={styles.link} to="/massages/#taimabhyanga">
              Taimabhyanga
            </Link>{' '}
            (femme enceinte),{' '}
            <Link className={styles.link} to="/massages/#massageBebe">
              Massage du jeune enfant
            </Link>
            .
            <br />
            <br />
            Ces massages pratiqués sur chaise ergonomique ou table de massage, vont agir sur votre système lymphatique
            et sanguin, sur vos énergies et ainsi vous permettre de vous ressourcer en vous relaxant.
            <br />
            <br />
          </p>
          <h2>Les massages habillés&nbsp;</h2>
          <p>
            <Link className={styles.link} to="/massages/#cranien">
              Crânien
            </Link>
            ,{' '}
            <Link className={styles.link} to="/massages/#thai_tradi">
              Thaï traditionnel
            </Link>{' '}
            (Nuad Boran),{' '}
            <Link className={styles.link} to="/massages/#thai_assis">
              Thaï assis
            </Link>
            ,{' '}
            <Link className={styles.link} to="/massages/#thai_pied">
              Thaï des pieds
            </Link>
            <br />
            <br />
            Ces massages pratiqués sur chaise ergonomique, table de massage ou tatami, vont agir sur vos méridiens, vos
            muscles et nerfs et ainsi vous permettre de vous relaxer en vous assouplissant.
          </p>
        </div>
      </div>
    </Section>
  );
};
