import React from 'react';
import { Link } from 'gatsby';

import * as styles from './footer.module.css';

export const Footer = () => {
  return (
    <div className={styles.main}>
      <Link className={styles.link} to="/">
        Accueil
      </Link>
      <div className={styles.separator} />
      <a className={styles.link} href="/mentionsLegales">
        Mentions Légales
      </a>
      <div className={styles.separator} />
      <Link className={styles.link} to="/charte">
        Charte
      </Link>
    </div>
  );
};
