import React from 'react';
import { Section } from './section';
import { Link } from 'gatsby';

import * as styles from './section.module.css';

export const AccueilSection = () => {
  return (
    <Section title="Aude Bien-Être, un lieu de détente au nord est de Nantes">
      <div>
        <p>
          Entre vie professionnelle et personnelle, que faites-vous pour vous ? Offrez-vous une parenthèse de Bien-Être,
          en venant vous détendre, vous retrouver, vous évader et prendre soin de vous.
        </p>
        <p>
          Des{' '}
          <Link className={styles.link} to="/massages/#massage_habille">
            massages habillés
          </Link>{' '}
          ou{' '}
          <Link className={styles.link} to="/massages/#massage_huile">
            massages à l'huile
          </Link>
          , de la{' '}
          <a className={styles.link} href="/relaxation">
            relaxation
          </a>{' '}
          ou de{' '}
          <a className={styles.link} href="/expression-corporelle">
            l’expression corporelle
          </a>
          , venez-vous ressourcer chez Aude Bien-Être.
        </p>
        <p>
          Aude Bien-Être est un espace dédié à vous accompagner par l’<strong>écoute</strong>, le <strong>soin</strong>{' '}
          et le <strong>conseil</strong> vers la détente et le Bien-Être. <br></br>C’est un lieu cosy où je serai à
          votre écoute pour personnaliser votre soin au plus près de vos besoins.
        </p>
        <p>
          Il est situé à Saint-Joseph de porterie, quartier du <strong>Nord-Est de Nantes</strong>, près de carquefou.
        </p>
        <p>
          Faites plaisir à vos proches et à vous même avec un{' '}
          <span>
            <strong>bon cadeau</strong> .
          </span>
        </p>
      </div>
    </Section>
  );
};
