import React from 'react';
import { Section } from './section';
import massageImage from '../images/expression_corporelle_pano_1.jpg';

import * as styles from './section.module.css';

export const ExpressionCorporelleSection = () => {
  return (
    <Section id="expression-corporelle" title="De l’expression corporelle">
      <img src={massageImage} alt="" className={styles.img} />
      <p>
        <a className={styles.link} href="/expression-corporelle">
          L'expression corporelle
        </a>{' '}
        se pratique avec ou sans matériel (tissus, balles, tapis ou coussins, etc…) sur de la musique pour vous amener à
        prendre conscience de votre corps au milieu des autres, avec soi-même et dans l’espace.
        <br />
        C’est un mélange de yoga, de danse et de relaxation.
      </p>
    </Section>
  );
};
